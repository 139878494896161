import { lazy, Suspense } from "react";
import { useTranslation } from "react-i18next";
import ErrorBoundary from "../components/shared/ErrorBoundary";
import LoadingIndicator from "../components/shared/LoadingIndicator";
import Page from "../components/shared/Page";
import { useTitle } from "../utils/gui";
import { Helmet } from "react-helmet-async";
import Button from "../components/Button";

const pages = new Map();

function ErrorContent({resetErrorBoundary, error})
{
  const {t} = useTranslation();

  const title = t('error.pageCrashed.title', 'Diese Seite ist abgestürzt');

  useTitle(title);

  return <Page>
    <Helmet><title>{title}</title></Helmet>
    <h1 className="text-3xl">{t('error.pageCrashed.heading', "Tut uns sehr Leid :(")}</h1>
    {error /*&& process.env.NODE_ENV === 'development'*/ && <>
      <p>{t('error.pageCrashed.copy', "Hier sind mehr Informationen, vielleicht hilfts?")}</p>
      <div className="ml-4 select-text">
        <p className="text-red-600 block"><b>{error.name}</b>: {error.message}</p>
        {error.stack && <samp className="text-red-800 text-xs block whitespace-pre-wrap">{error.stack}</samp>}
      </div>
    </>}
    {resetErrorBoundary && <Button onClick={resetErrorBoundary}>{t('error.pageCrashed.reloadPage', 'Seite neu laden')}</Button>}
  </Page>
}

export function Error(props)
{
  return <Suspense fallback=""><ErrorContent {...props} /></Suspense>
}

function LoadingContent({children})
{
  const {t} = useTranslation();

  useTitle(<LoadingIndicator />);

  return <Page>
    <Helmet><title>{t('common.loading', 'Lade...')}</title></Helmet>
    {children}
  </Page>;
}

export function Loading(props)
{
  return <Suspense fallback=""><LoadingContent {...props} /></Suspense>
}

export default function LazySafePage({page})
{
  let Page = pages.get(page);
  if (!Page) pages.set(page, Page = lazy(() => import(`./${page}`)));

  return <ErrorBoundary key={page} fallback={Error}>
    <Suspense fallback={<Loading />}>
      <Page />
    </Suspense>
  </ErrorBoundary>
}
