import { Helmet, HelmetProvider } from 'react-helmet-async';
import {BrowserRouter as Router} from 'react-router-dom';
import MainMenu from './layout/MainMenu';
import Routes, { MenuItems } from './Routes';
import './utils/shared/i18n';
import useGui from './utils/gui';
import { useTranslation } from 'react-i18next';
import Modal from './components/Modal';
import { Suspense, useState } from 'react';
import Button from './components/Button';
import Input from './components/Input';
import Label from './components/Label';
import { useDropzone } from 'react-dropzone';
import { AnimatePresence, motion } from "framer-motion";
import TitleBar from './layout/TitleBar';
import SideBar from './layout/SideBar';
import Upload from './icons/Upload';
import { TooltipContainer } from './components/shared/Tooltip';
import CacheBuster from 'react-cache-buster';
import {MessageBox} from './components/MessageBox';
import { ToastContainer } from 'react-toastify';

import { version } from '../package.json';

function AppContent()
{
  const globalUpload = useGui(s => s.globalUpload[0]);
  const {getRootProps, getInputProps, isDragActive, inputRef} = useDropzone({onDrop: globalUpload && globalUpload.onDrop});

  const {t} = useTranslation();

  const rootProps = globalUpload ? getRootProps({onClick: e => e.stopPropagation(), className: 'min-h-full w-full flex'}) : {className: 'min-h-full w-full flex'};
  const inputProps = globalUpload ? getInputProps({accept: globalUpload.accept}) : {};
  useGui.globalUploadInput = inputRef.current;

  const sideBarComponent = useGui(s => s.sideBar[0]);
  const sideBarSize = useGui(s => s.sideBarSize[0]);
  const sideBarProps = useGui(s => s.sideBarProps[0]);

  return <div {...rootProps}>
    <MainMenu version={version}><MenuItems /></MainMenu>
    <div className="flex flex-col flex-auto bg-white text-black">
      <TitleBar />
      <div className="flex flex-grow">
        <Routes />
        <SideBar Component={sideBarComponent} size={sideBarSize} props={sideBarProps} />
      </div>
    </div>
    {globalUpload && <input {...inputProps} />}
    <AnimatePresence>
      {isDragActive && <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}} transition={{ease: 'linear', duration: 0.15}} className="absolute w-full h-full bg-blue-500 bg-opacity-30 z-50 flex items-center justify-center p-4">
        <div className="flex flex-col items-center justify-center rounded-xl border-4 border-dashed border-blue-500 w-full h-full text-xl font-bold text-blue-500">
          <Upload className="w-56 h-56" />
          {t('copy.dropFilesToUpload', 'Dateien zum Hochladen ablegen')}
        </div>
      </motion.div>}
    </AnimatePresence>
    <MessageBox />
  </div>;
}

function LoginPrompt()
{
  const [loggingIn, setLoggingIn] = useState(false);
  const [error, setError] = useState();
  const [, updateAuthToken] = useGui(s => s.authToken);

  const {t} = useTranslation();

  async function tryLogin(e)
  {
    e.preventDefault();

    const userName = e.target.a4mUserName.value.trim();
    if (!userName) return e.target.a4mUserName.focus();
    const password = e.target.a4mPassword.value;
    if (!password) return e.target.a4mPassword.focus();
    const stayLoggedIn = e.target.a4mStayLoggedIn.checked;


    setLoggingIn(true);
    setError();
    try
    {
      const res = await fetch('/api/login', {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({userName, password, stayLoggedIn}),
      });
      const data = await res.json();
      if ((data && data.error) || !res.ok) throw data;
      window.setTimeout(updateAuthToken, 1);
    }
    catch (e)
    {
      setError(e && e.error && e.message ? e : {message: t('copy.errorConnecting', 'Fehler bei der Kommunikation mit dem Server. Bitte versuche es später noch einmal.'), e});
    }
    finally
    {
      setLoggingIn(false);
    }
  }

  return <div className="modal-open modal-overlay">
    <Helmet><title>{t('copy.login', 'Login')}</title></Helmet>
    <form className="modal-content max-w-xl top-1/3 p-6 flex flex-col gap-4" onSubmit={tryLogin}>
      <img className="m-auto" src="/logo.png" alt="ani4med" />{/*TODO svg*/}

      <div className="flex flex-col xs:grid grid-cols-2-auto xs:gap-2">
        <Label className="w-full" for="a4mUserName">{t('copy.userNameEmail', 'Benutzername/Email')}</Label>
        <Input className="mb-4 xs:m-0" name="a4mUserName" id="a4mUserName" autoFocus />
        <Label className="w-full" for="a4mPassword">{t('copy.password', 'Passwort')}</Label>
        <Input className="mb-4 xs:m-0" password name="a4mPassword" id="a4mPassword" />
        <div className="hidden xs:block" />
        <Label className="mr-auto flex gap-2 items-center"><input type="checkbox" name="a4mStayLoggedIn" />{t('copy.stayLoggedIn', 'Eingeloggt bleiben')}</Label>
      </div>
      {error && <div className="text-red-900 bg-red-200 border-red-300 border text-sm rounded p-2">
        <div className="font-bold">{t('copy.loginError', 'Fehler beim Login:')}</div>
        {error.message}
        {error.e && process.env.NODE_ENV === 'development' && <div className="text-black text-xs">{error.e.message}</div>}
      </div>}
      <Button primary submit className="self-end" disabled={loggingIn}>Login</Button>
    </form>
    <div className="text-xs text-gray-500 text-center absolute w-full bottom-0">{t('version', 'Version')} {version}</div>
  </div>;
}

const isProduction = process.env.NODE_ENV === 'production';
export default function App()
{
  const authToken = useGui(s => s.authToken[0]);
  const globalMessage = useGui(s => s.globalMessage[0]);

  return <CacheBuster currentVersion={version} isEnabled={isProduction}>
    <HelmetProvider>
      <Helmet titleTemplate={`%s | ${process.env.REACT_APP_APP_NAME}`} defaultTitle={process.env.REACT_APP_APP_NAME}>
      </Helmet>
      <Router>
        <Suspense fallback="">
          {authToken ? <AppContent /> : <LoginPrompt />}
        </Suspense>
      </Router>
      <TooltipContainer />
      <Modal isOpen={!!globalMessage} className="top-1/3 p-4 w-auto">{globalMessage}</Modal>
      <ToastContainer
        // toastClassName={() => 'bg-shade-700 shadow relative flex p-1 mb-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'}
        // bodyClassName="text-sm text-white"
        toastClassName={() => 'bg-white shadow-lg relative flex p-1 mb-2 min-h-10 rounded-md justify-between overflow-hidden cursor-pointer'}
        bodyClassName="text-sm text-black"
        closeButton={false}
        limit={5}
        hideProgressBar={true}
        />
    </HelmetProvider>
  </CacheBuster>;
}
