import { Link } from "react-router-dom";
import { useTooltip } from "./shared/Tooltip";

export default function ToolButton({onClick, children, className, tip})
{
  const fullClassName = `p-2 rounded hover:bg-white hover:bg-opacity-30 hover:shadow-sm ${className}`;
  const toolTip = useTooltip(tip);

  return typeof onClick === 'function'
    ? <button type="button" className={fullClassName} onClick={onClick} {...toolTip}>{children}</button>
    : <Link className={fullClassName} to={onClick} {...toolTip}>{children}</Link>;
}
