import produce from "immer";
import create from "zustand";
import tw from './tw';
import fscreen from 'fscreen';
import { useEffect, useMemo } from "react";
import { parseAuthToken, getCookie } from "./shared/gui";

export function getBreakpoint(width)
{
  for (let screen of tw.screens)
  {
    if (width >= screen[1])
    {
      return tw.bp[screen[0]];
    }
  }

  return 0;
}

const hotkeys = new Map();
const useGui = create(function(rawSet)
{
  const set = fn => rawSet(produce(fn));
  function setFullscreen(enable)
  {
    set(s => { if (s.fullscreen[0] !== enable) s.fullscreen[0] = enable });
  }

  function setter(key, doSet)
  {
    doSet = doSet || set;
    return v => doSet(s => { s[key][0] = v; });
  }

  function addSimpleFields(to, simpleFields, doSetter)
  {
    doSetter = doSetter || setter;
    for (let key in simpleFields)
    {
      const field = simpleFields[key];
      const [defaultValue, doSet] = Array.isArray(field) ? field : [field];
      to[key] = [defaultValue, doSetter(key, doSet)];
    }
  }

  window.addEventListener('resize', () => set(s => { s.bp = getBreakpoint(window.innerWidth) }));
  fscreen.onfullscreenchange = () => setFullscreen(!!fscreen.fullscreenElement);

  function handleKey(e)
  {
    set(s => { s.shiftKey = e.shiftKey; });
    if (e.type === 'keydown' && !e.repeat)
    {
      const specs = hotkeys.get(e.key.toLowerCase());
      if (specs)
      {
        for (const spec of specs)
        {
          if (e.ctrlKey === spec.ctrl && e.shiftKey === spec.shift && e.altKey === spec.alt && spec.handler) spec.handler(e);
        }
      }
    }
  }

  window.addEventListener('keydown', handleKey);
  window.addEventListener('keyup', handleKey);

  const fields = {
    bp: getBreakpoint(window.innerWidth),
    fullscreen: [false, setFullscreen],
    authToken: [parseAuthToken(getCookie('login')), () => set(s => { s.authToken[0] = parseAuthToken(getCookie('login')); })],
    shiftKey: false,
    uploads: [[], fn => set(s => { fn(s.uploads[0]); })],
  };

  const simpleFields = {
    globalMessage: null,
    forceMenuOpen: false,
    globalUpload: null,
    pageTitle: null,
    pageSubTitle: null,
    toolBar: null,
    sideBar: null,
    sideBarSize: null,
    sideBarProps: null,
    messageBox: null,
  };
  const pageState = {
    products: {
      selected: null,
    },
    product: {
      selectedTopic: null,
    },
    topic: {
      selectedChapter: null,
    },
    chapter: {
      selectedImage: null,
    },
    images: {
      selected: null,
    },
    videos: {
      selected: null,
    },
  };

  addSimpleFields(fields, simpleFields);
  for (let page in pageState)
  {
    function pageSetter(key, doSet)
    {
      doSet = doSet || set;
      return v => doSet(s => { s[page][key][0] = v; });
    }

    addSimpleFields(fields[page] = {}, pageState[page], pageSetter);
  }

  return fields;
});

function openGlobalUpload()
{
  useGui.globalUploadInput && useGui.globalUploadInput.click();
}

export function useGlobalUpload(accept, onDrop)
{
  const setGlobalUpload = useGui(s => s.globalUpload[1]);
  useEffect(function()
  {
    setGlobalUpload({accept, onDrop});
    return setGlobalUpload;
  }, [accept, onDrop, setGlobalUpload]);

  return openGlobalUpload;
}

export function useTitle(text)
{
  const setTitle = useGui(s => s.pageTitle[1]);
  useEffect(function()
  {
    setTitle(text);
    return setTitle;
  }, [text, setTitle]);
}

export function useSubTitle(text)
{
  const setSubTitle = useGui(s => s.pageSubTitle[1]);
  useEffect(function()
  {
    setSubTitle(text);
    return setSubTitle;
  }, [text, setSubTitle]);
}

export function useToolBar(generator, dependencies)
{
  // eslint-disable-next-line
  const items = useMemo(generator, dependencies);
  const setToolBar = useGui(s => s.toolBar[1]);
  useEffect(function()
  {
    setToolBar(items);
    return setToolBar;
  }, [items, setToolBar]);
}

export function useSideBar(component, size, sideBarProps)
{
  const setSideBar = useGui(s => s.sideBar[1]);
  const setSideBarSize = useGui(s => s.sideBarSize[1]);
  const setSideBarProps = useGui(s => s.sideBarProps[1]);
  useEffect(function()
  {
    setSideBar(component);
    setSideBarSize(size);
    setSideBarProps(sideBarProps);
    return setSideBar;
  }, [component, size, sideBarProps, setSideBar, setSideBarSize, setSideBarProps]);
}

export const entryToOption = e => ({value: e[0], label: e[1].name});

export function useHotkey(key, {ctrl, shift, alt}, handler)
{
  useEffect(() =>
  {
    const keyLower = key.toLowerCase();
    const specs = hotkeys.get(keyLower);
    const spec = {
      ctrl: !!ctrl,
      shift: !!shift,
      alt: !!alt,
      handler};
    if (specs) specs.push(spec);
    else hotkeys.set(keyLower, [spec]);

    return () =>
    {
      const specs = hotkeys.get(keyLower);
      if (!specs) return;

      const newSpecs = specs.filter(el => el !== spec);
      if (newSpecs.length) hotkeys.set(keyLower, newSpecs);
      else hotkeys.delete(keyLower);
    };
  }, [key, ctrl, shift, alt, handler]);
}

const prefixes = ['', 'K', 'M', 'G', 'T', 'P', 'E'];
export function bigNumber(value, unit = 'B', digits = 3, base = 1024)
{
  const abs = Math.abs(value);
  const pow = Math.min(Math.floor((abs >= 1 ? Math.log(abs) : 0) / Math.log(base)), prefixes.length - 1);

  return {
    value: (value / Math.pow(base, pow)).toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: digits}),
    unit: `${prefixes[pow]}${unit}`
  };
}

Object.assign(useGui, tw);
export default useGui;
