import { useState } from "react";
import { toast } from "react-toastify";
import i18n from "../utils/shared/i18n";
import useGui from "../utils/gui";
import Button from "./Button";
import Modal from "./Modal";

function moveModalPortalToEndOfBody(el)
{
  while (el)
  {
    if (el.classList.contains('ReactModalPortal')) return document.body.appendChild(el);
    el = el.parentElement;
  }
}

export function MessageBox()
{
  const [messageBoxIn, setMessageBoxIn] = useGui(s => s.messageBox);
  const [messageBox, setMessageBox] = useState();

  if (messageBoxIn !== messageBox && messageBoxIn) setMessageBox(messageBoxIn);

  const {title, content, buttons, cancelable, resolve, reject} = messageBox || {};

  function submit(e)
  {
    const button = buttons[e.nativeEvent?.submitter?.getAttribute('data-i')];
    if (!button) return false;

    (button.cancel ? reject : resolve)(button.value);
    setMessageBoxIn();
    return true;
  }

  const requestClose = cancelable ? () => { reject(); setMessageBoxIn(); } : undefined;

  return <Modal isOpen={!!messageBoxIn} className="w-128" onRequestClose={requestClose} onSubmit={submit}>
    <Modal.Header ref={moveModalPortalToEndOfBody}>{title}</Modal.Header>
    <Modal.Body>{content}</Modal.Body>
    <Modal.Footer className="gap-2">
      {buttons?.map((button, i) => <Button {...button.props} data-i={i} className="min-w-24" key={button.value} />)}
    </Modal.Footer>
  </Modal>;
}

export default function messageBox(title, content, buttons, cancelable)
{
  return new Promise((resolve, reject) =>
  {
    const setMessageBox = useGui.getState().messageBox[1];
    setMessageBox({title, content, buttons, cancelable, resolve, reject});
  });
}

function createButtons()
{
  messageBox.okCancel = [
    {props: {primary: true, submit: true, children: i18n.t('gui.ok', 'OK')}, value: 'OK'},
    {props: {submit: true, children: i18n.t('gui.cancel', 'Abbrechen')}, cancel: true, value: 'CANCEL'},
  ];
}

createButtons();
i18n.on('languageChanged', createButtons);

export function toParagraphs(str)
{
  return str.split('\n').map((line, i) => <p key={i} className="leading-5 my-2">{line}</p>);
}

export async function toastError(action)
{
  try
  {
    return await action();
  }
  catch (e)
  {
    toast.error(e && e.message ? e.message : i18n.t('copy.errorConnecting', 'Fehler bei der Kommunikation mit dem Server. Bitte versuche es später noch einmal.'));
  }
}
