import { usePopper } from "react-popper";
import { useState, useEffect, useMemo } from "react";

const fn = {};
const hide = () => fn.hide && fn.hide();

export function useTooltip(tip)
{
  useEffect(() => hide);
  return useMemo(() => tip ? {
    onMouseEnter: e => fn.show && fn.show(e.currentTarget, tip),
    onMouseLeave: hide,
  } : {}, [tip]);
}

export default function Tooltip({tip, children})
{
  return <div {...useTooltip(tip)}>{children}</div>;
}

export function TooltipContainer()
{
  const [tip, setTip] = useState();
  const [reference, setReference] = useState(null);
  const [popper, setPopper] = useState(null);
  const [arrow, setArrow] = useState(null);
  const {styles, attributes, forceUpdate} = usePopper(reference, popper, {
    modifiers: [
      {name: 'offset', options: {offset: [0, 4]}},
      {name: 'arrow', options: {element: arrow, padding: 5}},
    ],
    placement: 'top',
    strategy: 'fixed',
  });

  useEffect(() =>
  {
    fn.show = (reference, tip) =>
    {
      setTip(tip);
      setReference(reference);
      popper && popper.classList.add('block');
      forceUpdate && forceUpdate();
    };
    fn.hide = () => popper && popper.classList.remove('block');
  }, [forceUpdate, popper]);

  return <div className="absolute top-0 left-0">
    <div className="tooltip" ref={setPopper} style={styles.popper} {...attributes.popper}>
      <div className="arrow" ref={setArrow} style={styles.arrow} {...attributes.arrow} />
      {tip}
    </div>
  </div>;
}
