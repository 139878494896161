import { useTitle } from "../utils/gui";
import Page from "../components/shared/Page";

export default function NotFound({location})
{
  useTitle('Not found');

  return <Page>
    <h1>
      Uh-oh!
    </h1>
    <p>There's nothing there at <samp>{location.pathname}</samp></p>
  </Page>
}
