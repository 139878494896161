import React from "react";

export default class ErrorBoundary extends React.Component
{
  constructor(props)
  {
    super(props);
    this.state = {hasError: false};
  }

  static getDerivedStateFromError(error)
  {
    return {hasError: true, error};
  }

  render()
  {
    if (this.state.hasError)
    {
      const Fallback = this.props.fallback;
      return <Fallback resetErrorBoundary={() => this.setState({hasError: false})} error={this.state.error} />;
    }

    return this.props.children;
  }
}
