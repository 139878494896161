import ToolBar from "../components/ToolBar";
import useGui from "../utils/gui";
import { widthClassName } from "./MainMenu";

export const heightClassName = 'h-16';

export default function TitleBar()
{
  const title = useGui(s => s.pageTitle[0]);
  const subTitle = useGui(s => s.pageSubTitle[0]);
  const toolBar = useGui(s => s.toolBar[0]);

  return <header className={`${heightClassName} w-full flex-shrink-0 z-10`}>
    <div className={`${heightClassName} w-full fixed left-0 flex text-black`}>
      <div className={`${widthClassName} transition-all`} />
      <div className="flex flex-auto items-center p-2 bg-gray-200">
        <div className="flex flex-col flex-auto">
          {typeof title === 'string' ? <h1 className="text-2xl leading-none">{title}</h1> : title}
          {subTitle && (typeof subTitle === 'string' ? <h2 className="text-sm leading-none text-gray-600">{subTitle}</h2> : subTitle)}
        </div>
        <ToolBar items={toolBar} />
      </div>
    </div>
  </header>;
}
