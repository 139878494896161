import { useTranslation } from "react-i18next";
import useGui from "../utils/gui";
import { heightClassName } from "./TitleBar";

export default function SideBar({Component, size, props, flexible})
{
  const [uploads] = useGui(s => s.uploads);
  const effectiveSize = Component || uploads.length ? (size || 'w-48') : 'w-0';

  return <aside className={`${effectiveSize} delay-300 flex-shrink-0 transition-all z-10 pointer-events-none`}>
    <div className={`${!flexible && 'fixed'} h-full ${effectiveSize} transition-all delay-300 top-0 flex flex-col`}>
      <div className={`${!flexible && heightClassName} flex-shrink-0`}></div>
      <div className="w-full flex-grow p-2 bg-gray-300 pointer-events-auto">
        {Component && <Component {...props} />}
      </div>
      <Uploads uploads={uploads} />
    </div>
  </aside>;
}

function Uploads({uploads})
{
  const {t} = useTranslation();

  if (!uploads.length) return null;

  return <div className="bg-gray-400 pointer-events-auto flex flex-col text-sm">
    <h1 className="text-lg p-2 bg-gray-300 bg-opacity-30">{t('heading.uploads', 'Uploads')}</h1>
    <div className="flex flex-col flex-grow overflow-y-auto p-2 gap-2 max-h-full">
      {uploads.map(upload =>
      {
        const progress = (upload.progress + 0.5) / upload.numFiles * 100;
        const root = upload.type === 'image/*'
          ? t('menu.images', 'Bilder')
          : upload.type === 'video/*'
            ? t('menu.videos', 'Videos')
            : t('copy.unknown', 'Unbekannt');
        return <div key={upload.id} className="flex flex-col">
          {root}/{upload.label}
          <div className="flex flex-row gap-2 items-center">
            <div className="bg-gray-300 bg-opacity-50 h-3 flex-grow relative rounded overflow-hidden">
              <div className="bg-primary transition absolute top-0 left-0 h-full" style={{width: `${progress}%`}} />
            </div>
            {upload.progress}/{upload.numFiles}
          </div>
        </div>;
      })}
    </div>
  </div>;
}
