export const baseClassName = 'rounded px-2 py-1 active:shadow-inner disabled:opacity-50 disabled:cursor-default'
export const primaryClassName = 'bg-primary text-white active:bg-primary-dark';
export const secondaryClassName = 'bg-gray-200 text-black active:bg-gray-300';
export const tertiaryClassName = 'bg-gray-300 text-black active:bg-gray-400';
export const dangerClassName = 'bg-red-500 text-white active:bg-red-600';

export default function Button({ primary, danger, submit, className, ...props })
{
  return <button
    type={submit ? 'submit' : 'button'}
    {...props}
    className={`${baseClassName} ${
        primary ? primaryClassName
      : danger  ? dangerClassName
      :           secondaryClassName}
      ${className}`
    } />;
}
