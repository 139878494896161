import utf8 from "utf8";
import base64 from "base-64";
import { parse } from "./json";

export function getCookie(name)
{
  return document.cookie.match(`(?:^|;)\\s*${name}\\s*=\\s*([^;]+)`)?.pop() || '';
}

export function parseAuthToken(cookie)
{
  try
  {
    if (!cookie) return cookie;
    const token = parse(utf8.decode(base64.decode(cookie.split('.').pop())));
    token.per = Object.freeze(new Set(token.per));
    return Object.freeze(token);
  }
  catch
  {
    return null;
  }
}
