import { useTranslation } from 'react-i18next';
import {Redirect, Route, Switch} from 'react-router-dom';
import { MenuItem, Separator } from './layout/MainMenu';
import Category from './icons/Category';
import ImageIcon from "./icons/shared/Image";
import LazySafePage from './pages/LazySafePage';
import NotFound from './pages/NotFound';
import useGui from './utils/gui';
import Face from './icons/Face';
import Home from './icons/Home';
import Launch from './icons/Launch';
import Logout from './icons/shared/Logout';
import Person from './icons/shared/Person';
import { useTooltip } from './components/shared/Tooltip';
import Business from './icons/Business';
import Security from './icons/shared/Security';
import Movie from './icons/Movie';

export default function Routes()
{
  return <Switch>
    <Route exact path="/"><LazySafePage page="Home" /></Route>

    <Route path="/content">
      <Switch>
        <Route path="/content/products"><LazySafePage page="content/Products" /></Route>
        <Route path="/content/product/:id/version/:version"><LazySafePage page="content/Product" /></Route>
        <Route path="/content/topic/:id/version/:version"><LazySafePage page="content/Topic" /></Route>
        <Route path="/content/chapter/:id/version/:version"><LazySafePage page="content/Chapter" /></Route>
        <Redirect exact from="/content" to="/content/products" />
        <Route component={NotFound} />
      </Switch>
    </Route>
    <Route path="/images"><LazySafePage page="Images" /></Route>
    <Route path="/videos"><LazySafePage page="Videos" /></Route>

    <Route path={['/users/edit/:id', '/users/new', '/users']}><LazySafePage page="Users" /></Route>
    <Route path={['/acl/edit/:id', '/acl/new', '/acl']}><LazySafePage page="Acl" /></Route>
    <Route path={['/clients/edit/:id', '/clients/new', '/clients']}><LazySafePage page="Clients" /></Route>

    <Route path="/launch"><LazySafePage page="Launch" /></Route>

    <Route path="/profile"><LazySafePage page="MyProfile" /></Route>

    <Route component={NotFound} />
  </Switch>
}

export function MenuItems()
{
  const {t} = useTranslation();

  const updateAuthToken = useGui(s => s.authToken[1]);
  const setGlobalMessage = useGui(s => s.globalMessage[1])

  async function logout()
  {
    setGlobalMessage(t('copy.loggingOut', 'Ausloggen...'));
    document.cookie = 'login=';
    try
    {
      const res = await fetch('/api/logout', {method: 'POST'});
      const data = await res.json();
      if (data.error || !res.ok) throw data;
    }
    catch
    {
    }
    finally
    {
      updateAuthToken();
      setGlobalMessage();
    }
  }

  return <>
    <section className="flex-shrink-0">
      <MenuItem to="/" icon={Home} exact>{t('menu.home', 'Home')}</MenuItem>
    </section>
    <Separator />
    <section className="overflow-y-auto">
      <MenuItem to="/content" icon={Category}>{t('menu.content', 'Content')}</MenuItem>
      <MenuItem to="/images" icon={ImageIcon}>{t('menu.images', 'Bilder')}</MenuItem>
      <MenuItem to="/videos" icon={Movie}>{t('menu.videos', 'Videos')}</MenuItem>
    </section>
    <Separator />
    <section className="mt-auto flex-shrink-0">
      <MenuItem to="/users" icon={Person}>{t('menu.users', 'Benutzer')}</MenuItem>
      <MenuItem to="/acl" icon={Security}>{t('menu.acl', 'Zugangskontrolle')}</MenuItem>
      <MenuItem to="/clients" icon={Business}>{t('menu.clients', 'Kunden')}</MenuItem>
    </section>
    <Separator />
    <section className="mt-auto flex-shrink-0">
      <MenuItem to="/launch" icon={Launch}>{t('menu.launchFrontend', 'Frontend starten')}</MenuItem>
    </section>
    <Separator />
    <section className="flex-shrink-0">
      <div className="flex flex-wrap">
        <MenuItem to="/profile" icon={Face} className="flex-grow">{t('menu.profile', 'Mein Profil')}</MenuItem>
        <button type="button" className="hover:bg-gray-300 p-2 flex-none" onClick={logout} {...useTooltip(t('tool.logout', 'Ausloggen'))}><Logout /></button>
      </div>
    </section>
  </>
}
