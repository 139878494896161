import { useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Page({children, onClick, className, noP})
{
  const { pathname } = useLocation();

  useLayoutEffect(() =>
  {
    window.scrollTo(0, 0);
  }, [pathname]);

  return <main className={`w-full ${!noP && 'p-4'} ${className}`} onClick={onClick}>{children}</main>;
}
