import preval from 'babel-plugin-preval/macro';

const tw = preval`
  const resolveConfig = require('tailwindcss/resolveConfig');
  const tailwindConfig = require('../../tailwind.config.js');

  const fullConfig = resolveConfig(tailwindConfig);
  const screens = Object.entries(fullConfig.theme.screens)
    .map(e => [e[0], Number.parseInt(e[1])])
    .sort((l, r) => l[1] - r[1]);
  const bp = Object.fromEntries(screens.map((s, i) => [s[0], i + 1]));
  screens.reverse();
  module.exports = {
    screens,
    bp,
  };`;
export default tw;
