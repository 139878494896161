import { NavLink, useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { useEffect } from "react";
import fscreen from 'fscreen';
import { Suspense } from "react";
import useGui from "../utils/gui";
import { heightClassName } from "./TitleBar";
import FullscreenExit from "../icons/shared/FullscreenExit";
import Fullscreen from "../icons/shared/Fullscreen";
import Menu from "../icons/Menu";
import MenuOpen from "../icons/MenuOpen";
import { useTranslation } from "react-i18next";

function useIsOpen()
{
  const bp = useGui(s => s.bp);
  const [forceOpen] = useGui(s => s.forceMenuOpen);
  return bp >= useGui.bp.lg || forceOpen;
}

function toggleFullscreen()
{
  const [fullscreen, setFullscreen] = useGui.getState().fullscreen;
  if (!fullscreen)
  {
    fscreen.requestFullscreen(document.documentElement)
      .then(() => setFullscreen(true))
      .catch(() => setFullscreen(false));
  }
  else
  {
    fscreen.exitFullscreen()
      .then(() => setFullscreen(false))
      .catch(() => setFullscreen(false));
  }
}

export function MenuItem({to, children, icon: Icon, exact, className})
{
  const isOpen = useIsOpen();
  return <NavLink to={to} className={`flex items-center justify-start p-2 hover:bg-gray-300 ${className}`} activeClassName="bg-gray-300" exact={exact}>
    {Icon && <Icon className="flex-shrink-0" />}
    {isOpen && <span className="whitespace-nowrap overflow-ellipsis overflow-hidden pl-1">{children}</span>}
  </NavLink>;
}

export function Separator()
{
  return <hr className="mx-2 border-gray-300 my-1" />
}

export const widthClassName = 'w-10 lg:w-48';

export default function MainMenu({version, children})
{
  const bp = useGui(s => s.bp);
  const [forceOpen, setForceOpen] = useGui(s => s.forceMenuOpen);
  const [fullscreen] = useGui(s => s.fullscreen);
  const isOpen = bp >= useGui.bp.lg || forceOpen;
  const history = useHistory();
  const {t} = useTranslation();

  if (bp >= useGui.bp.lg && forceOpen) window.setTimeout(() => setForceOpen(false), 0);

  useEffect(function()
  {
    if (!forceOpen) return;

    function handleEsc(e)
    {
      if (e.key === 'Escape')
      {
        e.preventDefault();
        setForceOpen(false);
      }
    }

    const unlisten = history.listen(function(_, action)
    {
      if (action === 'PUSH') setForceOpen(false);
    });
    window.addEventListener('keydown', handleEsc);
    document.body.classList.add('menu-open');

    return () =>
    {
      window.removeEventListener('keydown', handleEsc);
      unlisten();
      document.body.classList.remove('menu-open');
    };
  }, [forceOpen, setForceOpen, history]);

  return <>
    <nav className={`${widthClassName} h-full z-20 transition-all flex-shrink-0`}>
      <div className={`${widthClassName} ${forceOpen && 'w-4/5 md:w-48 shadow-xl lg:shadow-none'} transition-all h-full fixed flex flex-col text-black bg-gray-200`}>
        <div className={`flex items-center justify-start ${heightClassName} flex-shrink-0 overflow-hidden`}>
          <button onClick={() => setForceOpen(!forceOpen)} className="p-2 lg:hidden">
            {isOpen ? <MenuOpen /> : <Menu />}
          </button>
          <div className="text-xs text-gray-500 m-auto mr-0 lg:mr-auto lg:ml-3">{t('version', 'Version')} {version}</div>
          {fscreen.fullscreenEnabled && (bp < useGui.bp.lg || fscreen.fullscreenElement) && <button onClick={toggleFullscreen} className="p-2 ml-auto">
            {fullscreen ? <FullscreenExit /> : <Fullscreen />}
          </button>}
        </div>
        <Suspense fallback="">
          {children}
        </Suspense>
      </div>
    </nav>
    <AnimatePresence>
      {forceOpen && <motion.div initial={{opacity: 0}} animate={{opacity: 0.2}} exit={{opacity: 0}} transition={{ease: 'linear', duration: 0.15}} className="bg-black fixed w-full h-full z-10" onClick={() => setForceOpen(false)} />}
    </AnimatePresence>
  </>;
}
