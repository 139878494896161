import ToolButton from "./ToolButton";

export default function ToolBar({items})
{
  return items
    ? <div className="flex">{items.map(function(item, i)
      {
        const Icon = item[0];
        return <ToolButton key={i} onClick={item[1]} tip={item[2]}><Icon /></ToolButton>;
      })}</div>
    : null;
}
