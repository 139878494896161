import fastJson from 'fast-json-stringify';
import sjson from 'secure-json-parse';

export const stringify = fastJson({});
export const parse = sjson.parse;
export function tryParse(json, defaultValue)
{
  try
  {
    return sjson.parse(json);
  }
  catch
  {
    return defaultValue;
  }
}
