import ReactModal from 'react-modal';
import { useMemo, forwardRef } from 'react';
import Close from '../icons/shared/Close';

ReactModal.setAppElement('#root');

const className = {
  base: 'modal-content',
  afterOpen: '',
  beforeClose: '',
};
const overlayClassName = {
  base: 'modal-overlay',
  afterOpen: 'modal-open',
  beforeClose: 'modal-closing',
};

function Modal({children, className: extraClassName, onSubmit, onRequestClose, ...props})
{
  const theClassName = !extraClassName
    ? className
    : {
      base: `${className.base} ${extraClassName}`,
      afterOpen: '',
      beforeClose: '',
    };
  const contentElement = useMemo(() => onSubmit
    ? (props, children) => <form {...props} onSubmit={async e => { e.preventDefault(); e.stopPropagation(); if (await onSubmit(e)) onRequestClose(); }}>{children}</form>
    : (props, children) => <div {...props}>{children}</div>,
    [onSubmit, onRequestClose]);
  return <ReactModal
    {...props}
    onRequestClose={onRequestClose}
    className={theClassName}
    overlayClassName={overlayClassName}
    bodyOpenClassName="overflow-hidden"
    closeTimeoutMS={200}
    contentElement={contentElement}>
    {children}
  </ReactModal>;
}

const ModalHeader = forwardRef(function ModalHeader({children, onRequestClose, extraContent, className}, ref)
{
  return <div className="flex items-center p-2 border-b bg-black bg-opacity-5" ref={ref}>
    <span className={`pr-2 mr-auto ${className}`}>{children}</span>
    {extraContent}
    {onRequestClose && <button type="button" onClick={onRequestClose} className="flex items-end ml-2"><Close className="w-4 mx-1" /></button>}
  </div>;
});

function ModalBody({children, className, seamless, ...props})
{
  return <div className={`${!seamless && 'p-2'} overflow-y-auto flex-shrink ${className}`} {...props}>{children}</div>;
}

function ModalFooter({children, className, ...props})
{
  return <div className={`flex p-2 border-t bg-black bg-opacity-5 justify-end ${className}`} {...props}>{children}</div>;
}

Modal.Header = ModalHeader;
Modal.Body = ModalBody;
Modal.Footer = ModalFooter;

export default Modal;
